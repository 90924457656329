
import { defineComponent } from 'vue';
import NotFound from '@/views/NotFound.vue';
import ECheckForm from '@/components/ECheckForm.vue';
import CardForm from '@/components/CardForm.vue';

export default defineComponent({
  components: { NotFound, ECheckForm, CardForm },
  props: {
    form: String,
    currentContact: Object,
    primaPayAccountId: String,
    primaPayTrustAccountId: String,
  },
  setup(props) {
    const isNotFound = props.form !== 'card' && props.form !== 'e-check';
    const formComponent = `${props.form}-form`;

    const onPay = async (requestFn: () => Promise<any>) => {
      try {
        await requestFn();
      } catch (error) {
        console.error(error);
      }
    };

    return {
      isNotFound,
      formComponent,
      onPay,
    };
  },
});
