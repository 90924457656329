<template>
  <NotFound v-if="isNotFound" />
  <div class="PaymentForm" v-else>
    <component
      :is="formComponent"
      :contactId="currentContact.id"
      :primaPayAccountId="primaPayAccountId"
      :primaPayTrustAccountId="primaPayTrustAccountId"
      :onlyForm="true"
    >
      <template #default="{ primaPayRequestFn }">
        <img
          style="position: absolute; margin-top: 10px; width: 100px"
          src="../assets/images/primaPay/primaPay.png"
          alt=""
        />

        <AButton
          type="primary"
          style="float: right"
          @click="onPay(primaPayRequestFn)"
        >
          Use This {{ formComponent === 'card-form' ? 'Card' : 'Account' }}
        </AButton>
      </template>
    </component>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import NotFound from '@/views/NotFound.vue';
import ECheckForm from '@/components/ECheckForm.vue';
import CardForm from '@/components/CardForm.vue';

export default defineComponent({
  components: { NotFound, ECheckForm, CardForm },
  props: {
    form: String,
    currentContact: Object,
    primaPayAccountId: String,
    primaPayTrustAccountId: String,
  },
  setup(props) {
    const isNotFound = props.form !== 'card' && props.form !== 'e-check';
    const formComponent = `${props.form}-form`;

    const onPay = async (requestFn: () => Promise<any>) => {
      try {
        await requestFn();
      } catch (error) {
        console.error(error);
      }
    };

    return {
      isNotFound,
      formComponent,
      onPay,
    };
  },
});
</script>

<style lang="scss" scoped>
.PaymentForm {
  margin: 0 auto;
  padding: 1rem;
}
</style>
